import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { XIcon } from '@heroicons/react/solid';
import { useState } from 'react';

const Container = styled.div`
  ${tw`flex items-center relative justify-center w-screen bg-primary text-white`}
  min-height: 40px;
  font-weight: 700;

  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.5s;
  animation-name: slideIn;
  animation-duration: 0.5s;

  @-webkit-keyframes slideIn {
    0% {
      transform: translateY(-80px);
    }

    100% {
      transform: translateY(0);
    }
  }
`;

const P = styled.p`
  ${tw`md:text-sm xl:text-base max-width[75%] text-center py-2`}
  font-size: 10px;
`;

const X = styled.p`
  ${tw`absolute my-auto right-4`}
`;

const TokenAddress = styled.a`
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: underline;

  @media (min-width: 350px) {
    max-width: 250px;
  }

  @media (min-width: 400px) {
    max-width: 300px;
  }

  @media (min-width: 768px) {
    display: inline;
  }
`;

export const Flash = () => {
  const { formatMessage, locale } = useIntl();
  const [close, onClose] = useState(true);

  return close ? (
    <Container className="slide-in">
      <P>
        {formatMessage({ id: 'flash.tokenAddress' })}{' '}
        <TokenAddress
          href="https://polygonscan.com/address/0x4a98c81663b2a204514e79b161d3d8e1a86d7712"
          target="_blank"
        >
          0x4a98c81663b2a204514e79b161d3d8e1a86d7712
        </TokenAddress>
      </P>
      <X>
        <XIcon tw="w-5 h-5" onClick={() => onClose(false)} />
      </X>
    </Container>
  ) : null;
};

// {locale === 'pl' ? (
//   <P
//     href="https://medium.com/@bidda_nft/ostatnia-szansa-na-zostanie-stakeholderem-96d25a8fa066"
//     target="blank"
//   >
//     {formatMessage({ id: 'flash.titleOne' })}
//   </P>
// ) : (
//   <P
//     href="https://medium.com/@bidda_nft/last-chance-to-become-a-stakeholder-edb8b010e78b"
//     target="blank"
//   >
//     {formatMessage({ id: 'flash.titleOne' })}{' '}
//     <span tw="underline font-weight[800]">
//       {formatMessage({ id: 'flash.titleTwo' })}
//     </span>
//   </P>
// )}
