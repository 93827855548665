import tw, { styled, css } from 'twin.macro';

export const HeroHeader = styled.header`
  ${tw`w-full`}
  min-height: 800px;
`;

export const HeroHeaderICO = styled.header`
  ${tw`w-full`}
  min-height: 800px;
`;

export const HeroHeader404 = styled.header`
  ${tw`w-full`}
  min-height: 800px;
`;
