import React from 'react';
import '../styles/global.css';

import { GlobalStyles } from 'twin.macro';

const Layout = ({ children, ...rest }) => (
  <div tw="h-full w-full" {...rest}>
    <GlobalStyles />
    {children}
  </div>
);

export default Layout;
