import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Fade } from 'react-awesome-reveal';
import logodark from '../images/ico/logodark.svg';

import { useIntl, Link, changeLocale } from 'gatsby-plugin-react-intl';
import { English, MenuClose, MenuIcon, Polish } from '../styles/icons';
import { Flash } from './flash';

export interface NavigationProps {}

export const NavContainer = styled.nav`
  ${tw`w-full px-9`}
  background: rgba(255, 255, 255, 0.98);
`;

export const NavLink = styled(Link)`
  ${tw`font-medium text-black text-lg lg:mr-16 last:mr-0 hover:text-primary`}
`;

export const MobileNavLink = styled(Link)`
  ${tw`font-medium text-black w-full md:w-3/4 bg-gray-100 p-5 mt-5 rounded-lg text-center text-lg hover:text-primary`}
`;

export const CustomLinkMobile = styled.a`
  ${tw`font-medium text-black w-full md:w-3/4 bg-gray-100 p-5 mt-5 rounded-lg text-center text-lg hover:text-primary`}
`;

export const CustomLink = styled.a`
  ${tw`font-medium text-black text-lg lg:mr-16 hover:text-primary`}
`;

export const MobileNav = styled.div`
  ${tw`fixed flex flex-col w-full h-full z-50 right--0.5 top--1 px-8 pb-4 bg-white`}
`;

export const isBrowser = () => typeof window !== 'undefined';

export const getURL = () => {
  return process.env.GATSBY_APP_URL || process.env.APP_URL;
};

export const getApiURL = () => {
  return process.env.GATSBY_API_URL || process.env.API_URL;
};

export const NavigationIco = ({ location }) => {
  const [menuFlag, setMenuFlag] = useState(false);
  const { formatMessage, locale } = useIntl();

  const t = (id: string) => formatMessage({ id });

  const handleMenu = () => {
    setMenuFlag(!menuFlag);
  };

  return (
    <NavContainer tw="flex flex-col items-center justify-between fixed top-0 z-50">
      <Flash />
      <div tw="flex container mx-auto justify-between pt-5">
        <NavLink to="/#">
          <img
            src={logodark}
            width={120}
            height={80}
            alt="our Logo"
            tw="mb-4"
          />
        </NavLink>
        <div tw="flex flex-row items-center mr--7 md:ml--16 mt--1 lg:hidden">
          <English
            tw="w-6 h-6 mr-2 cursor-pointer hover:opacity-70"
            onClick={() => changeLocale('en')}
          />
          <Polish
            tw="w-6 h-6 mr-6 cursor-pointer hover:opacity-70"
            onClick={() => changeLocale('pl')}
          />
        </div>
        <ul tw="hidden lg:flex items-center">
          <div tw="hidden lg:flex flex-row items-center mr--7 md:ml--16 mt--1 pr-6">
            <English
              tw="w-6 h-6 mr-2 cursor-pointer hover:opacity-70"
              onClick={() => changeLocale('en')}
            />
            <Polish
              tw="w-6 h-6 mr-6 cursor-pointer hover:opacity-70"
              onClick={() => changeLocale('pl')}
            />
          </div>
          <CustomLink href={'/business'} id="click-gtag-nav-invest-landing-ico">
            {t('navigation.business')}
          </CustomLink>

          <NavLink to="/#token-economy">{t('navigation.tokenEconomy')}</NavLink>
          <NavLink to="/#roadmap">{t('navigation.roadmap')}</NavLink>
          <NavLink to="/#whitepaper">{t('navigation.whitepaper')}</NavLink>
        </ul>
        {!menuFlag ? (
          <div tw="flex flex-row items-center lg:hidden text-black z-20">
            <MenuIcon
              tw="lg:hidden w-12 h-12 text-black flex self-center md:mr-2"
              onClick={() => {
                handleMenu();
                document.body.classList.add('stop-scrolling');
              }}
            />
          </div>
        ) : (
          <div tw="flex flex-row items-center lg:hidden w-12 h-12 text-black z-20 pl-1.5">
            <English
              tw="w-6 h-6 ml-2 mr-2 cursor-pointer hover:opacity-70"
              onClick={() => changeLocale('en')}
            />
            <Polish
              tw="w-6 h-6 mr-1 cursor-pointer hover:opacity-70"
              onClick={() => changeLocale('pl')}
            />
            <MenuClose
              onClick={() => {
                handleMenu();
                document.body.classList.remove('stop-scrolling');
              }}
              tw="ml-2"
            />
          </div>
        )}
        {menuFlag ? (
          <MobileNav>
            <div tw="flex flex-row items-center justify-between lg:hidden text-black z-20">
              <img
                src={logodark}
                width={120}
                height={80}
                alt="our Logo"
                tw="mb-3.5 ml-0.5"
              />
              <div tw="flex flex-row mt--0.5 md:ml--16">
                <English
                  tw="w-6 h-6 ml-0.5 mr-2 cursor-pointer hover:opacity-70"
                  onClick={() => changeLocale('en')}
                />
                <Polish
                  tw="w-6 h-6 cursor-pointer hover:opacity-70"
                  onClick={() => changeLocale('pl')}
                />
              </div>

              <MenuClose
                onClick={() => {
                  handleMenu();
                  document.body.classList.remove('stop-scrolling');
                }}
                tw="mr--7 md:mr--5"
              />
            </div>
            <Fade
              direction={'right'}
              cascade
              duration={150}
              tw="flex justify-center"
            >
              <MobileNavLink
                to="/#whitepaper"
                onClick={() => {
                  handleMenu();
                  document.body.classList.remove('stop-scrolling');
                }}
              >
                {t('navigation.whitepaper')}
              </MobileNavLink>
              <MobileNavLink
                to="/#token-economy"
                onClick={() => {
                  handleMenu();
                  document.body.classList.remove('stop-scrolling');
                }}
              >
                {t('navigation.tokenEconomy')}
              </MobileNavLink>
              <MobileNavLink
                to="/#roadmap"
                onClick={() => {
                  handleMenu();
                  document.body.classList.remove('stop-scrolling');
                }}
              >
                {t('navigation.roadmap')}
              </MobileNavLink>
              <CustomLinkMobile href={'/business'}>
                {t('navigation.business')}
              </CustomLinkMobile>
            </Fade>
          </MobileNav>
        ) : null}
      </div>
    </NavContainer>
  );
};
