import React, { ButtonHTMLAttributes } from 'react';
import tw, { styled, css } from 'twin.macro';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactChild;
  default?: boolean;
  primary: boolean;
  block?: boolean;
  small?: boolean;
  shadow?: boolean;
}

const ButtonContainer = styled.button(
  ({ block, primary, small, disabled, shadow }: ButtonProps) => [
    tw`flex items-center justify-center w-full h-16 rounded-full md:w-32 focus:outline-none`,
    block && tw`w-full`,
    small && tw`w-32 h-12`,
    primary &&
      css`
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      `,
    shadow &&
      css`
        box-shadow: 0px 8px 24px rgba(99, 181, 241, 0.4);
      `,
  ]
);

export const Button = ({
  children,
  primary = false,
  small = false,
  ...rest
}: ButtonProps) => {
  return (
    <ButtonContainer primary={primary} small={small} {...rest}>
      <span>{children}</span>
    </ButtonContainer>
  );
};
